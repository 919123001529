import React from "react";
import { Grommet, ThemeType } from "grommet";
import styled, { css } from "styled-components";
import { IntlProvider } from "react-intl";
import messages from "../../translations";

interface AppProps {
  children: React.ReactNode;
  theme?: ThemeType;
}

const DEFAULT_LANGUAGE = "pt-br";

const SITE_LANGUAGE =
  process.env.SITE_LANGUAGUE ||
  process.env.GATSBY_SITE_LANGUAGUE ||
  process.env.GATSBY_VERCEL_SITE_LANGUAGUE ||
  DEFAULT_LANGUAGE;

export const App: React.FC<AppProps> = ({ children, theme }) => {
  const defaultTheme: ThemeType = {
    global: {
      colors: {
        brandPrimary: "#3d00b8",
        brandSecondary: "#f2f2f2",
        gray64: "#5C5C5C",
        gray32: "#ADADAD",
      },
      font: {
        family: "Inter",
      },
      input: {
        font: {
          weight: 400,
        },
      },
    },
    page: {
      wide: {
        width: {
          min: "300px",
          max: "1366px",
        },
      },
    },
    button: {
      default: {},
      primary: {
        background: {
          color: "brandPrimary",
        },
      },
      hover: {
        primary: {
          background: {
            color: "#4b11cf",
          },
        },
      },
    },
    anchor: {
      hover: {
        extend: css`
          text-decoration: none;
          color: ${(props) => props.theme.global?.colors?.brandPrimary};
        `,
      },
    },
    paragraph: {
      extend: css`
        color: ${(props) => props.theme.global?.colors?.gray64};
        font-size: 1rem;
      `,
    },
    heading: {
      color: {
        dark: "brandSecondary",
        light: "gray64",
      },
    },
  };

  return (
    <IntlProvider
      locale={SITE_LANGUAGE}
      defaultLocale={SITE_LANGUAGE}
      messages={messages[SITE_LANGUAGE]}
    >
      <StyledGrommet full="min" theme={{ ...defaultTheme, ...theme }}>
        {children}
      </StyledGrommet>
    </IntlProvider>
  );
};

const StyledGrommet = styled(Grommet)`
  display: flex;
  flex-direction: column;
`;

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-pt-br-tsx": () => import("./../../../src/pages/contact/pt-br.tsx" /* webpackChunkName: "component---src-pages-contact-pt-br-tsx" */),
  "component---src-pages-contact-pt-tsx": () => import("./../../../src/pages/contact/pt.tsx" /* webpackChunkName: "component---src-pages-contact-pt-tsx" */),
  "component---src-pages-hiring-index-tsx": () => import("./../../../src/pages/hiring/index.tsx" /* webpackChunkName: "component---src-pages-hiring-index-tsx" */),
  "component---src-pages-hiring-pt-br-tsx": () => import("./../../../src/pages/hiring/pt-br.tsx" /* webpackChunkName: "component---src-pages-hiring-pt-br-tsx" */),
  "component---src-pages-hiring-pt-tsx": () => import("./../../../src/pages/hiring/pt.tsx" /* webpackChunkName: "component---src-pages-hiring-pt-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-privacy-policy-pt-br-tsx": () => import("./../../../src/pages/privacy-policy/pt-br.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-pt-br-tsx" */),
  "component---src-pages-privacy-policy-pt-tsx": () => import("./../../../src/pages/privacy-policy/pt.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-pt-tsx" */),
  "component---src-pages-services-capacity-index-tsx": () => import("./../../../src/pages/services/capacity/index.tsx" /* webpackChunkName: "component---src-pages-services-capacity-index-tsx" */),
  "component---src-pages-services-capacity-pt-br-tsx": () => import("./../../../src/pages/services/capacity/pt-br.tsx" /* webpackChunkName: "component---src-pages-services-capacity-pt-br-tsx" */),
  "component---src-pages-services-capacity-pt-tsx": () => import("./../../../src/pages/services/capacity/pt.tsx" /* webpackChunkName: "component---src-pages-services-capacity-pt-tsx" */),
  "component---src-pages-services-cloud-index-tsx": () => import("./../../../src/pages/services/cloud/index.tsx" /* webpackChunkName: "component---src-pages-services-cloud-index-tsx" */),
  "component---src-pages-services-cloud-pt-br-tsx": () => import("./../../../src/pages/services/cloud/pt-br.tsx" /* webpackChunkName: "component---src-pages-services-cloud-pt-br-tsx" */),
  "component---src-pages-services-cloud-pt-tsx": () => import("./../../../src/pages/services/cloud/pt.tsx" /* webpackChunkName: "component---src-pages-services-cloud-pt-tsx" */),
  "component---src-pages-services-consulting-index-tsx": () => import("./../../../src/pages/services/consulting/index.tsx" /* webpackChunkName: "component---src-pages-services-consulting-index-tsx" */),
  "component---src-pages-services-consulting-pt-br-tsx": () => import("./../../../src/pages/services/consulting/pt-br.tsx" /* webpackChunkName: "component---src-pages-services-consulting-pt-br-tsx" */),
  "component---src-pages-services-consulting-pt-tsx": () => import("./../../../src/pages/services/consulting/pt.tsx" /* webpackChunkName: "component---src-pages-services-consulting-pt-tsx" */),
  "component---src-pages-services-data-index-tsx": () => import("./../../../src/pages/services/data/index.tsx" /* webpackChunkName: "component---src-pages-services-data-index-tsx" */),
  "component---src-pages-services-data-pt-br-tsx": () => import("./../../../src/pages/services/data/pt-br.tsx" /* webpackChunkName: "component---src-pages-services-data-pt-br-tsx" */),
  "component---src-pages-services-data-pt-tsx": () => import("./../../../src/pages/services/data/pt.tsx" /* webpackChunkName: "component---src-pages-services-data-pt-tsx" */),
  "component---src-pages-services-digital-index-tsx": () => import("./../../../src/pages/services/digital/index.tsx" /* webpackChunkName: "component---src-pages-services-digital-index-tsx" */),
  "component---src-pages-services-digital-pt-br-tsx": () => import("./../../../src/pages/services/digital/pt-br.tsx" /* webpackChunkName: "component---src-pages-services-digital-pt-br-tsx" */),
  "component---src-pages-services-digital-pt-tsx": () => import("./../../../src/pages/services/digital/pt.tsx" /* webpackChunkName: "component---src-pages-services-digital-pt-tsx" */),
  "component---src-pages-services-generative-ai-index-tsx": () => import("./../../../src/pages/services/generative-ai/index.tsx" /* webpackChunkName: "component---src-pages-services-generative-ai-index-tsx" */),
  "component---src-pages-services-generative-ai-pt-br-tsx": () => import("./../../../src/pages/services/generative-ai/pt-br.tsx" /* webpackChunkName: "component---src-pages-services-generative-ai-pt-br-tsx" */),
  "component---src-pages-services-generative-ai-pt-tsx": () => import("./../../../src/pages/services/generative-ai/pt.tsx" /* webpackChunkName: "component---src-pages-services-generative-ai-pt-tsx" */),
  "component---src-pages-services-hunting-index-tsx": () => import("./../../../src/pages/services/hunting/index.tsx" /* webpackChunkName: "component---src-pages-services-hunting-index-tsx" */),
  "component---src-pages-services-hunting-pt-br-tsx": () => import("./../../../src/pages/services/hunting/pt-br.tsx" /* webpackChunkName: "component---src-pages-services-hunting-pt-br-tsx" */),
  "component---src-pages-services-hunting-pt-tsx": () => import("./../../../src/pages/services/hunting/pt.tsx" /* webpackChunkName: "component---src-pages-services-hunting-pt-tsx" */),
  "component---src-pages-services-outsourcing-index-tsx": () => import("./../../../src/pages/services/outsourcing/index.tsx" /* webpackChunkName: "component---src-pages-services-outsourcing-index-tsx" */),
  "component---src-pages-services-outsourcing-pt-br-tsx": () => import("./../../../src/pages/services/outsourcing/pt-br.tsx" /* webpackChunkName: "component---src-pages-services-outsourcing-pt-br-tsx" */),
  "component---src-pages-services-outsourcing-pt-tsx": () => import("./../../../src/pages/services/outsourcing/pt.tsx" /* webpackChunkName: "component---src-pages-services-outsourcing-pt-tsx" */),
  "component---src-pages-services-professionals-index-tsx": () => import("./../../../src/pages/services/professionals/index.tsx" /* webpackChunkName: "component---src-pages-services-professionals-index-tsx" */),
  "component---src-pages-services-professionals-pt-br-tsx": () => import("./../../../src/pages/services/professionals/pt-br.tsx" /* webpackChunkName: "component---src-pages-services-professionals-pt-br-tsx" */),
  "component---src-pages-services-professionals-pt-tsx": () => import("./../../../src/pages/services/professionals/pt.tsx" /* webpackChunkName: "component---src-pages-services-professionals-pt-tsx" */),
  "component---src-pages-services-solutions-index-tsx": () => import("./../../../src/pages/services/solutions/index.tsx" /* webpackChunkName: "component---src-pages-services-solutions-index-tsx" */),
  "component---src-pages-services-solutions-pt-br-tsx": () => import("./../../../src/pages/services/solutions/pt-br.tsx" /* webpackChunkName: "component---src-pages-services-solutions-pt-br-tsx" */),
  "component---src-pages-services-solutions-pt-tsx": () => import("./../../../src/pages/services/solutions/pt.tsx" /* webpackChunkName: "component---src-pages-services-solutions-pt-tsx" */),
  "component---src-pages-services-technology-index-tsx": () => import("./../../../src/pages/services/technology/index.tsx" /* webpackChunkName: "component---src-pages-services-technology-index-tsx" */),
  "component---src-pages-services-technology-pt-br-tsx": () => import("./../../../src/pages/services/technology/pt-br.tsx" /* webpackChunkName: "component---src-pages-services-technology-pt-br-tsx" */),
  "component---src-pages-services-technology-pt-tsx": () => import("./../../../src/pages/services/technology/pt.tsx" /* webpackChunkName: "component---src-pages-services-technology-pt-tsx" */)
}


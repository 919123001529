export default {
  // HEADER
  "header.workWithUs": "Trabalhe conosco",
  "header.workWithUs.link":
    "/hiring",

  // FEATURED BANNER
  "featuredBanner.title.outsourcing": "Outsourcing",
  "featuredBanner.title.outsourcingbodyshop": "Outsourcing, Body Shop",
  "featuredBanner.title.habitant": "Habitant",
  "featuredBanner.title.generativeAI": "Generative AI",
  "featuredBanner.title.hunting": "Hunting",
  "featuredBanner.title.growth": "Digital",
  "featuredBanner.title.cloud": "Cloud",
  "featuredBanner.title.dataarceng": "Data Architecture & Engineering",
  "featuredBanner.title.cloudManagement": "Data Governance",
  "featuredBanner.title.dataScience": "Analytics & Data Science",

  "featuredBanner.link.hunting": "/services/hunting",

  "featuredBanner.text.outsourcing":
    "Estamos prontos para fazer você ter sucesso no âmbito estratégico da sua Transformação Digital.",
  "featuredBanner.text.hunting":
    "Melhor escalabilidade e velocidade na entrega de insights e modelos para o seu negócio.",

  "featuredBanner.button.join": "Quero fazer parte",
  "featuredBanner.button.join.link":
    "/contact",

  "featuredBanner.button.resolve": "Resolve o meu problema!",
  "featuredBanner.button.outsourcingbodyshop": "Outsourcing, Bodyshop",
  "featuredBanner.button.resolve.link":
    "/contact",

  // ABOUT
  "about.title.blockTitle": "Data Marketplace",
  "about.text.theCompany":
    "A Data Marketplace é uma startup baseada em dados que tem a missão de divulgar a cultura “data-driven” e apoiar os nossos clientes através de soluções e serviços na sua jornada de transformação.",

  // SERVICE BLOCK
  "serviceBlock.button.readMore": "Saiba mais",

  "serviceBlock.consulting.title": "Consulting",
  "serviceBlock.consulting.text":
    "Apoio estratégico na sua jornada baseada em dados. Fazemos isso, realizando um diagnóstico e preparando a equipa para criar produtos através da inteligência empresarial, transformação digital e ciência dos dados.",

  "serviceBlock.cloud.title": "Cloud",
  "serviceBlock.cloud.text":
    "Independente da escolha da sua nuvem, nós realizamos a gestão completa para você não se preocupar com nada.",

  "serviceBlock.technology.title": "Technology",
  "serviceBlock.technology.text":
    "Realizamos o fornecimento de tecnologias adequadas e das competências necessárias para prover a solução de forma completa.",

  "serviceBlock.solutions.title": "Solutions",
  "serviceBlock.solutions.text":
    "Conheça as soluções que temos prontas para te atender. Processos, tecnologia e informação integrados para você.",

  // PARTNER CAROUSEL
  "partnerCarousel.title.partners": "Parceiros",

  // PARTNER CAROUSEL
  "footer.blockTitle.brand": "Data Marketplace",
  "footer.blockTitle.social": "Redes sociais",
  "footer.blockTitle.email": "Email",

  "footer.menuItem.about": "Sobre",
  "footer.menuItem.policyPrivacy": "Política de privacidade",
  "footer.menuItem.weAreHiring": "Contato",

  // SOCIAL NETWORKING LINKS
  "social.link.instagram": "https://www.instagram.com/datamarketplace/",
  "social.link.facebook": "https://www.facebook.com/datamarketplacebr",
  "social.link.linkedin":
    "https://www.linkedin.com/company/data-marketplace-portugal",
};
